<template>
  <div class="_page">
    <div class="_body" >
      <el-card
        class="_frequencyList"
        shadow="always"
        v-for="(item, index) in frequencyList"
        :key="index"
      >
        <div class="_titleBox">{{ item.title }}</div>

        <div class="_contentBox">
          <i
            class="el-icon-arrow-left"
            style="font-size: 30px; font-weight: 600; color: #666666"
            @click="sub(index)"
          ></i>
          <input
            class="_inputBox"
            type="text"
            :value="item.hertz"
            @input="getHertz($event, index)"
          />
          <i
            class="el-icon-arrow-right"
            style="font-size: 30px; font-weight: 600; color: #666666"
            @click="add(index)"
          ></i>
        </div>
        <div class="_stateBox1" v-if="item.state" @click="close(index)">
          <p
            style="
              font-size: 11px;
              position: absolute;
              top: 5px;
              font-weight: 500;
            "
          >
            当前状态
          </p>
          <p>打开</p>
        </div>
        <div class="_stateBox2" v-if="!item.state" @click="open(index)">
          <p
            style="
              font-size: 11px;
              position: absolute;
              top: 5px;
              font-weight: 500;
            "
          >
            当前状态
          </p>
          <p>关闭</p>
        </div>
      </el-card>

      <el-card class="_frequencyList" shadow="always">
        <div
          class="_playBox"
          v-if="!isPlay"
          style="background-color: #327742"
          @click="playAudio"
        >
          <p>PLAY</p>
          <i class="el-icon-video-play" style="padding-left: 50px"></i>
        </div>
        <div
          class="_playBox"
          v-if="isPlay"
          style="background-color: #d65950"
          @click="stopAudio"
        >
          <p>STOP</p>
          <i class="el-icon-video-pause" style="padding-left: 50px"></i>
        </div>
      </el-card>
    </div>
  </div>
</template>


<style lang="less">
._page {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  ._body {
    width: 738px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    ._frequencyList {
      margin-top: 30px;
      width: 90%;
      height: 120px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
    .el-card__body {
      width: 100%;
      padding: 0;
      display: flex;
      justify-content: space-between;
      ._playBox {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #616161;
        color: white;
        font-size: 30px;
        font-weight: 600;
      }
      ._titleBox {
        width: 18%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #efefef;
        height: 100%;
        color: #e1e1e1;
        font-size: 30px;
        font-weight: 620;
      }
      ._contentBox {
        width: 50%;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        ._inputBox {
          width: 40%;
          height: 100%;
          border: solid 1px rgb(255, 255, 255);
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
          border-radius: 10px;
          font-size: 30px;
          font-weight: 600;
          color: #8e8d8d;
        }
      }
      ._stateBox1 {
        width: 18%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #327742;
        height: 100%;
        flex-direction: column;
        color: white;
        font-weight: 600;
        position: relative;
        font-size: 15px;
      }
      ._stateBox2 {
        width: 18%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #d65950;
        height: 100%;
        flex-direction: column;
        color: white;
        font-weight: 600;
        position: relative;
        font-size: 15px;
      }
    }
    ._frequencyList1 {
      position: fixed;
      bottom: 15px;
      width: 90%;
      height: 120px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      
    }
  }
}
</style>

<script>
import * as Tone from "tone";
//const synth = new Tone.Synth().toDestination();
const synth = new Tone.PolySynth(Tone.Synth).toDestination();
const now = Tone.now(); // now一定跟在这个synth一个域下，不然会出问题
var interval1 = null;
var interval2 = null;
var interval3 = null;

export default {
  data() {
    return {
      frequencyList: [
        {
          hertz: 220,
          play: false,
          title: "1",
          state: true,
        },
        {
          hertz: 230,
          play: false,
          title: "2",
          state: false,
        },
        {
          hertz: 260,
          play: false,
          title: "3",
          state: false,
        },
      ],
      isPlay: false,
      playNmber: 1,
      hertzList: [],
    };
  },

  methods: {
    getHertz(event, index) {
      console.log(event);
      if (event.currentTarget.value > 0 && event.currentTarget.value < 1201) {
        this.frequencyList[index].hertz = event.currentTarget.value;
      } else {
        this.$message.error("输入错误，正确范围20-12000");
      }
    },

    //音频播放
    playAudio() {
      if (this.playNmber > 0) {
        var hertz = [];
        this.isPlay = true;
        for (var i = 0; i < 3; i++) {
          if (this.frequencyList[i].state) {
            hertz.push(this.frequencyList[i].hertz);
          }
        }
        if (this.playNmber === 1) {
          synth.triggerAttack(hertz[0], now);
        } else if (this.playNmber === 2) {
          synth.triggerAttack(hertz[0], now);
          synth.triggerAttack(hertz[1], now + 0.5);
        } else if (this.playNmber === 3) {
          synth.triggerAttack(hertz[0], now);
          synth.triggerAttack(hertz[1], now + 0.5);
          synth.triggerAttack(hertz[2], now + 1);
        }
        this.hertzList = hertz;
      } else {
        this.$message.error("请保持至少打开一条通道");
      }
    },

    //音频停止
    stopAudio() {
      //synth.triggerRelease(now + 1)
      synth.triggerRelease(this.hertzList, now);
      this.isPlay = false;
    },

    setDivSize() {
      this.divWidth = window.innerWidth;
      this.divHeight = window.innerHeight;
    },

    //减频率
    sub(index) {
      this.stopAudio();
      if (this.frequencyList[index].hertz > 0) {
        var newNumber = this.frequencyList[index].hertz * 10 - 1;
        this.frequencyList[index].hertz = newNumber / 10;
        this.$forceUpdate();
      }
    },
    add(index) {
      this.stopAudio();
      if (this.frequencyList[index].hertz < 12000) {
        var newNumber = this.frequencyList[index].hertz * 10 + 1;
        this.frequencyList[index].hertz = newNumber / 10;
        this.$forceUpdate();
      }
    },
    //打开通道
    open(index) {
      if (this.isPlay) {
        this.stopAudio();
        this.frequencyList[index].state = true;
        this.playNmber++;
        this.playAudio();
      } else {
        this.frequencyList[index].state = true;
        this.playNmber++;
      }
    },
    close(index) {
      if (this.playNmber > 1) {
        if (this.isPlay) {
          this.stopAudio();
          this.frequencyList[index].state = false;
          this.playNmber--;
          this.playAudio();
        } else {
          this.frequencyList[index].state = false;
          this.playNmber--;
        }
      } else {
        this.$message.warning("请保持至少打开一条通道");
      }
    },
  },

  mounted() {
    this.setDivSize();
    window.onresize = () => {
      this.setDivSize();
    };
  },
};
</script>