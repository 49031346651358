import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import * as Tone from 'tone'


Vue.use(ElementUI);
const synth = new Tone.Synth().toDestination();

Vue.config.productionTip = false

//全局前置导航


new Vue({
  render: h => h(App),
}).$mount('#app')
